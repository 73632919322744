import { useState } from 'react';
import { useEffect } from 'react';
import './Result.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import AdminService from '../../services/admin.service';
import "react-datepicker/dist/react-datepicker.css";
import {CopyToClipboard} from "react-copy-to-clipboard/src";

const Result = ({ _id, name, phone, resdate, reqdate, option, text, amount, RVstatus, reloadData, setReloadData }) => {
    const [ resdateSlice, setResDateSlice ] = useState('');
    const [ reqdateSlice, setReqDateSlice ] = useState('');
    const [ phoneSlice, setPhoneSlice ] = useState('');
    const [ statusClass, setStatusClass ] = useState('');
    const [ resultRVstatus, setResultRVstatus] =  useState(RVstatus);
    const [ open, setOpen ] = useState(false);

    useEffect(()=>{
        const resDateString = String(resdate)
        const reqDateString = String(reqdate)
        setResDateSlice(resDateString.slice(0, 4)+"-"+resDateString.slice(4, 6)+"-"+resDateString.slice(6, 8))
        setReqDateSlice(reqDateString.slice(2, 4)+"."+reqDateString.slice(4, 6)+"."+reqDateString.slice(6, 8))
        setPhoneSlice(phone.slice(0, 3)+"-"+phone.slice(3, 7)+"-"+phone.slice(7, 11))
    }, [])

    useEffect(()=>{
        const setClassName = ()=>{
            if(RVstatus === '신규상담'){
                setStatusClass("blue")
            }
            if(RVstatus === '접수완료'){
                setStatusClass("green")
            }
            if(RVstatus === '상담완료'){
                setStatusClass("gray")
            }
            if(RVstatus === '상담취소'){
                setStatusClass("white")
            }
        }
        setClassName();
    })

    const UpdateBtn = ()=>{
        AdminService.update( _id, resultRVstatus ).then(result => {
            alert('상태 변경 완료')
            setReloadData(!reloadData)
        })
    }
    return(
        <div className="Result">
            <div className="List">
                <div className='ListHeader DF-Row'>
                    <div className='DF-Row'>
                        <p className={ statusClass + ' Status' } >{ RVstatus }</p>
                        <p className='RegistDate'>접수일 { reqdateSlice }</p>
                    </div>
                    <p className='Option'>{ option }</p>
                </div>
                <div className='ContentWrap'>
                    <p className={!open ? 'Content' : 'ContentOpen'}>{ text }</p>
                </div>
                <div className='DateTimeAmount DF-Row'>
                    <div className='DF-Row'>
                        <p className='Date'>상담일 { resdateSlice }</p>
                    </div>
                </div>
                <CopyToClipboard text={ phone } onCopy={() => alert("클립보드에 복사되었습니다.")}>
                    <div className="NamePhone">
                        <text>{ name } { phoneSlice }</text>
                    </div>
                </CopyToClipboard>
                <div className={!open ? "SelectClose" : "SelectOpen"}>
                    <select className="SelectOption" id='SelectOption' defaultValue={ RVstatus } onChange={
                        (e)=>{
                            if(e.target.value == "상담취소"){
                                alert('상담 취소를 선택하셨습니다.')
                                setResultRVstatus(e.target.value);
                            } else {
                                setResultRVstatus(e.target.value);
                            }
                        }
                    } required>
                        <option value="신규상담">신규상담</option>
                        <option value="접수완료">접수완료</option>
                        <option value="상담완료">상담완료</option>
                        <option value="상담취소">상담취소</option>
                    </select>
                    <button className='SelectBtn' onClick={ UpdateBtn }>변경</button>
                </div>
                <div className={'ChevronDown'} onClick={()=>{ setOpen(!open) }}>
                    {
                        !open
                        ? <FontAwesomeIcon icon={ faChevronDown }/>
                        : <FontAwesomeIcon icon={ faChevronUp }/>
                    }</div>
            </div>
        </div>
    )
}
export default Result;