import './Navbar.css'

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

import AuthService from '../../services/auth.service';
import DatePicker from "react-datepicker";
import ko from 'date-fns/locale/ko';
import { setLogout } from '../../redux/userSlice';
import { useNavigate } from 'react-router-dom';

const Navbar = ({  
    setStatus, 
    setOption, 
    dataTotal, 
    dataReady, 
    dataConfirm, 
    dataCompelete, 
    dataDeny, 
    setResStartDate, 
    setResEndDate, 
    setReqStartDate, 
    setReqEndDate,
    filterText,
    setFilterText
})=>{
    const user = useSelector(state =>state.user);
    console.log(user)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentDate = new Date();
    const newDate = new Date();
    const oneYearAgo = new Date(newDate.setFullYear(newDate.getFullYear() - 1));
    console.log(oneYearAgo)
    const settingDate = (e)=>{
        const year = e.getFullYear().toString().substr(-2);
        const month = ('0' + (e.getMonth() + 1)).slice(-2);
        const day = ('0' + e.getDate()).slice(-2);
        const resultDate = `${year}.${month}.${day}`
        return resultDate
    }
    const NumberDate = (e)=>{
        const year = e.getFullYear();
        const month = ('0' + (e.getMonth() + 1)).slice(-2);
        const day = ('0' + e.getDate()).slice(-2);
        const resultDate = `${year}${month}${day}`
        return Number(resultDate)
    }
    const [ openModal, setOpenModal ] = useState({
        resStartModal : false,
        resEndModal : false,
        reqStartModal : false,
        reqEndModal : false
    });
    const {
        resStartModal,
        resEndModal,
        reqStartModal,
        reqEndModal
    } = openModal;

    const modalRef = useRef();

    useEffect(() => {
        const handler = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                setOpenModal({
                    ...openModal,
                    resStartModal : false,
                    resEndModal : false,
                    reqStartModal : false,
                    reqEndModal : false
                });
            }
        };
        document.addEventListener('mousedown', handler);
        document.addEventListener('touchstart', handler); // 모바일 대응
        
        return () => {
            document.removeEventListener('mousedown', handler);
            document.removeEventListener('touchstart', handler); // 모바일 대응
        };
    });

    const [ pickDate, setPickDate ] = useState({
        resStartDate : oneYearAgo,
        resEndDate : currentDate,
        reqStartDate : oneYearAgo,
        reqEndDate : currentDate
    })

    const {
        resStartDate,
        resEndDate,
        reqStartDate,
        reqEndDate
    } = pickDate;

    const [ dateString, setDateString ] = useState({
        resStartString : settingDate(oneYearAgo),
        resEndString : settingDate(currentDate),
        reqStartString : settingDate(oneYearAgo),
        reqEndString : settingDate(currentDate)
    })

    const {
        resStartString,
        resEndString,
        reqStartString,
        reqEndString
    } = dateString;

    const HandleResStartDate = (date)=>{
        setPickDate({
            ...pickDate,
            resStartDate : date
        })
        setDateString({
            ...dateString,
            resStartString : settingDate(date)
        })
        setOpenModal({
            ...openModal,
            resStartModal : false
        })
        setResStartDate(NumberDate(date))
    }

    const HandleResEndDate = (date)=>{
        setPickDate({
            ...pickDate,
            resEndDate : date
        })
        setDateString({
            ...dateString,
            resEndString : settingDate(date)
        })
        setOpenModal({
            ...openModal,
            resEndModal : false
        })
        setResEndDate(NumberDate(date))
    }

    const HandleReqStartDate = (date)=>{
        setPickDate({
            ...pickDate,
            reqStartDate : date
        })
        setDateString({
            ...dateString,
            reqStartString : settingDate(date)
        })
        setOpenModal({
            ...openModal,
            reqStartModal : false
        })
        setReqStartDate(NumberDate(date))
    }

    const HandleReqEndDate = (date)=>{
        setPickDate({
            ...pickDate,
            reqEndDate : date
        })
        setDateString({
            ...dateString,
            reqEndString : settingDate(date)
        })
        setOpenModal({
            ...openModal,
            reqEndModal : false
        })
        setReqEndDate(NumberDate(date))
    }
    useEffect(()=>{
        if(resStartDate > resEndDate){
            setPickDate({
                ...pickDate,
                resEndDate : resStartDate
            })
            setDateString({
                ...dateString,
                resEndString : settingDate(resStartDate)
            })
            setReqEndDate(NumberDate(resStartDate))
        }
        if(reqStartDate > reqEndDate){
            setPickDate({
                ...pickDate,
                reqEndDate : reqStartDate
            })
            setDateString({
                ...dateString,
                reqEndString : settingDate(reqStartDate)
            })
            setReqEndDate(NumberDate(reqStartDate))
        }
    }, [resStartDate, resEndDate, reqStartDate, reqEndDate])
    return(
        <div className="Navbar">
            <div className='WelcomeWrap DF-Row Container'>
                <h2 className='Welcome'>{ user.Name } 님 환영합니다.</h2>
                <button className='LogoutBtn' onClick={()=>{      
                    dispatch(setLogout());
                    navigate('/')
                }}>로그아웃</button>
            </div>
            <div className='CategoryWrap DF-Row Container'>
                <div className="dataTotalNum DF-Row" onClick={()=>{
                    setStatus('')
                }}>
                    <p className='MR-05'>전체</p>
                    <p>{ 
                        dataTotal < 10
                        ? "0" + dataTotal
                        : dataTotal
                        }</p>
                </div>
            </div>
            <div className="CategoryWrap DF-Row Container">
                <div className="Category Ct01 DF-Row" onClick={()=>{
                    setStatus('신규상담')
                }}>
                    <p className='MR-05'>신규상담</p>
                    <p>{ 
                        dataReady < 10
                        ? "0" + dataReady
                        : dataReady
                        }</p>
                </div>
                <div className="Category Ct02 DF-Row" onClick={()=>{
                    setStatus('접수완료')
                }}>
                    <p className='MR-05'>접수완료</p>
                    <p>{ 
                        dataConfirm < 100
                        ? "0" + dataConfirm
                        : dataConfirm
                        }</p>
                </div>
                <div className="Category Ct03 DF-Row" onClick={()=>{
                    setStatus('상담완료')
                }}>
                    <p className='MR-05'>상담완료</p>
                    <p>{ 
                    dataCompelete < 100
                    ? "0" + dataCompelete
                    : dataCompelete
                    }</p>
                </div>
                <div className="Category Ct04 DF-Row" onClick={()=>{
                    setStatus('상담취소')
                }}>
                    <p className='MR-05'>상담취소</p>
                    <p>{ 
                    dataDeny < 100
                    ? "0" + dataDeny
                    : dataDeny
                    }</p>
                </div>
            </div>
            <div className="DateOptionWrap Container">
                <div className="DateWrap DF-Row">
                    <div className="Date DF-Row">
                        <p className='Label MR-10'>접수일</p>
                        <p onClick={ () => {
                            setOpenModal({
                                ...openModal,
                                reqStartModal : !reqStartModal
                            })
                        }} className='SelectedDate'>{ reqStartString }</p>
                        <p> ~ </p>
                        <p onClick={ () => {
                            setOpenModal({
                                ...openModal,
                                reqEndModal : !reqEndModal
                            })
                        }} className='SelectedDate'>{ reqEndString }</p>
                    </div>
                    <div className="Date DF-Row">
                        <p className='Label MR-10'>상담일</p>
                        <p onClick={ () => {
                            setOpenModal({
                                ...openModal,
                                resStartModal : !resStartModal
                            })
                        }} className='SelectedDate'>{ resStartString }</p>
                        <p>~</p>
                        <p onClick={ () => {
                            setOpenModal({
                                ...openModal,
                                resEndModal : !resEndModal
                            })
                        }} className='SelectedDate'>{ resEndString }</p>
                    </div>
                    {
                        !resStartModal
                        ? null
                        :                             
                        <div ref={ modalRef } className='DatePickWrap'>
                            <DatePicker
                                selected={resStartDate} 
                                startDate={resStartDate}
                                onChange={ HandleResStartDate }
                                locale={ko}
                                inline={true}
                            />
                        </div>
                    }
                    {
                        !resEndModal
                        ? null
                        :                             
                        <div ref={ modalRef } className='DatePickWrap'>
                            <DatePicker
                                selected={ resEndDate } 
                                startDate={ resEndDate }
                                minDate={ resStartDate }
                                onChange={ HandleResEndDate } 
                                locale={ko}
                                // selectsRange={true}
                                inline={true}
                            />
                        </div>
                    }
                    {
                        !reqStartModal
                        ? null
                        :                             
                        <div ref={ modalRef } className='DatePickWrap'>
                            <DatePicker
                                selected={ reqStartDate } 
                                startDate={ reqStartDate }
                                maxDate={currentDate}
                                onChange={ HandleReqStartDate } 
                                locale={ko}
                                inline={true}
                            />
                        </div>
                    }
                    {
                        !reqEndModal
                        ? null
                        :                             
                        <div ref={ modalRef } className='DatePickWrap'>
                            <DatePicker
                                selected={ reqEndDate } 
                                startDate={ reqEndDate }
                                minDate={ reqStartDate }
                                maxDate={currentDate}
                                onChange={ HandleReqEndDate } 
                                locale={ko}
                                inline={true}
                            />
                        </div>
                    }
                    

                </div>
                <div className="OptionWrap DF-Row">
                    <select className="Option Op01 PL-7" defaultValue="상담분류" onChange={(e)=>{
                        setOption(e.target.value)
                    }} required>
                        <option value="상담분류" disabled>상담분류</option>
                        <option value="">전체</option>
                        <option value="성폭력 / 성범죄">성폭력 / 성범죄</option>
                        <option value="부동산 / 임대차">부동산 / 임대차</option>
                        <option value="이혼 / 상속 / 재산분할">이혼 / 상속 / 재산분할</option>
                        <option value="민사">민사</option>
                        <option value="형사">형사</option>
                        <option value="기타 문의">기타 문의</option>
                    </select>
                    {/* <select className="Option Op02 PL-7" defaultValue="상담시간" onChange={(e)=>{
                        setAmount(e.target.value)
                    }} required>
                        <option value="상담시간" disabled>상담시간</option>
                        <option value="">전체</option>
                        <option value="100">10분</option>
                        <option value="88000">20분</option>
                    </select> */}
                    <input className="Option Op03 PL-7" type="text" placeholder='상세검색' onChange={(e)=>{
                        setFilterText(e.target.value)
                    }}/>
                    {/* <select className="Option Op03 PL-7" defaultValue="상세검색" required>
                        <option value="상세검색" disabled>상세검색</option>
                        <option value="?">?</option>
                    </select> */}
                </div>
            </div>
        </div>
    )
}


export default Navbar;