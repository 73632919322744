import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import userSlice from './userSlice';

const reducers = combineReducers({
    user : userSlice.reducer
});
  
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user']
};
  
const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: false,
    }),
});
  
export default store;