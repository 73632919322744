import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    ID : '',
    Name: '',
    isLogined : false
}
let userSlice = createSlice({
    name : 'user',
    initialState : initialState ,
    reducers : {
        setReduxLogin(state, action){
            state.ID = action.payload.ID
            state.Name = action.payload.Name
            state.isLogined = true
        },
        setLogout(state, action){
            state.ID = ''
            state.Name = ''
            state.isLogined = false
        }
    }
}) 
export let { setReduxLogin, setLogout } = userSlice.actions;
export default userSlice;

