import api from "./api";

class AdminService {
  reservation(){
    return api.get("/admin/reservation");
  }
  
  update(
    _id, 
    RVstatus
  ){
    return api.post("/admin/update", {
      _id,
      RVstatus
    });
  }
}

export default new AdminService();
