import axios from "axios";

const instance = axios.create({
    baseURL: "https://admin.lawfirmjk.co.kr/api",
    // baseURL: "http://junggokadmin-env.eba-eypydvxj.ap-northeast-2.elasticbeanstalk.com/api",
    // baseURL: "http://jgapi.ap-northeast-2.elasticbeanstalk.com/",
    // baseURL: "http://localhost:8080/api",
    headers: {
        "Content-Type": "application/json",
    },
});

export default instance;