import './Reset.css';
import './App.css';
import Navbar from './pages/Navbar/Navbar';
import Result from './pages/Result/Result';
import Login from './pages/Login/Login';
import { useCallback, useContext, useEffect, useState } from 'react';
import adminService from './services/admin.service';
import { useSelector } from 'react-redux';
import Main from './pages/Main.jsx/Main';

function App() {
  const user = useSelector(state =>state.user);

  return (
    <div className="App">
      {/* <Main /> */}
      {
        !user.isLogined
        ? <Login />
        : <Main />
      }
    </div>
  );
}

export default App;
