import api from "./api";
import TokenService from "./token.service";

class AuthService {
  login(        
      ID,
      Password
    ) {
    return api
      .post("/auth/signin", {
        ID,
        Password
      })
      .then(response => {
        if (response.data.accessToken) {
          TokenService.setUser(response.data);
        }
        return response.data;
      });
  }

  logout() {
    TokenService.removeUser();
  }

  userinfo(ID){
    return api.post("/auth/userinfo", {
      ID
    });
  }

  regist(
      ID,
      Password
    ) {
    return api.post("/auth/signup", {
      ID,
      Password
    });
  }
  overlap(ID) {
    return api.post("/auth/overlap", {
      ID
    });
  }
  changePassword(
      ID, 
      Password, 
      newPassword
    ) {
    return api.post("/auth/changepassword", {
      ID, 
      Password, 
      newPassword
    });
  }
}

export default new AuthService();
