import api from "./api";

class UserService {
  login(
        ID, 
        PW
    ){
    return api.post("/user/login", {
        ID, 
        PW
    });
  }
}

export default new UserService();
