import { useEffect, useState } from 'react';

import Navbar from '../Navbar/Navbar';
import Result from '../Result/Result';
import adminService from '../../services/admin.service';

const Main = ()=>{
    const [ status, setStatus ] = useState('');
    const [ option, setOption ] = useState('');
    const [ data, setData ] = useState([]);
    const [ filteredList, setFilteredList ] = useState([]);
    const [ resStartDate, setResStartDate ] = useState(null);
    const [ resEndDate, setResEndDate ] = useState(null);
    const [ reqStartDate, setReqStartDate ] = useState(null);
    const [ reqEndDate, setReqEndDate ] = useState(null);
    const [ reloadData, setReloadData] = useState(false);
    const [ filterText, setFilterText ] = useState('');

    console.log('data', data)
    console.log(filterText)

    useEffect(()=>{
        const LoadData = async ()=>{
        await adminService.reservation().then(result=>{
            setData(result.data)
        })
        }
        LoadData();
    }, [reloadData])

    const filterData = () => {
    if (
        status &&
        option &&
        resStartDate &&
        resEndDate &&
        reqStartDate &&
        reqEndDate &&
        filterText
    ) {
        setFilteredList(data);
    } else {
        const filteredList = data.reduce((acc, cur) => {
        const statusCondition = status
            ? cur.RVstatus === status
            : true;
        const optionCondition = option
            ? cur.option.includes(option)
            : true;
        const resStartDateCondition = resStartDate
            ? cur.resdate >= resStartDate
            : true;
        const resEndDateCondition = resEndDate
            ? cur.resdate <= resEndDate
            : true;
        const reqStartDateCondition = reqStartDate
            ? cur.reqdate >= reqStartDate
            : true;
        const reqEndDateCondition = reqEndDate
            ? cur.reqdate <= reqEndDate
            : true;
        const filterTextCondition = filterText
            ? cur.name.includes(filterText) || cur.text.includes(filterText)
            : true;
    if (
        statusCondition &&
        optionCondition &&
        resStartDateCondition &&
        resEndDateCondition &&
        reqStartDateCondition &&
        reqEndDateCondition &&
        filterTextCondition
    ) {
        acc.push(cur);
    }
    return acc;
    }, []);
    setFilteredList(filteredList);
    }
    };
    useEffect(() => {
    filterData();
    }, [data, status, option, resStartDate, resEndDate, reqStartDate, reqEndDate, reloadData, filterText]);

    const dataReady = data.filter( data => 
    data.RVstatus === "신규상담"
    )
    const dataConfirm = data.filter( data => 
    data.RVstatus === "접수완료"
    )
    const dataCompelete = data.filter( data => 
    data.RVstatus === "상담완료"
    )
    const dataDeny = data.filter( data => 
    data.RVstatus === "상담취소"
    )

    return(
        <div className="AppContainer">
            <Navbar 
                setStatus={ setStatus }
                setOption = { setOption }
                dataTotal = { data.length }
                dataReady={ dataReady.length }
                dataConfirm ={ dataConfirm.length }
                dataCompelete={ dataCompelete.length }
                dataDeny={ dataDeny.length }
                setResStartDate = { setResStartDate }
                setResEndDate = { setResEndDate }
                setReqStartDate = { setReqStartDate }
                setReqEndDate = { setReqEndDate }
                filterText = { filterText }
                setFilterText = { setFilterText }
            />
            <div className='Container'>
                {
                filteredList.length === 0
                ? <h2 className='ListNull'>해당 조건에 맞는 결과가 없습니다</h2>
                :
                filteredList.map((list, index) =>{
                    return <Result 
                    _id = { list._id }
                    key ={index} 
                    name ={ list.name }
                    phone ={ list.phone }
                    resdate = { list.resdate}
                    reqdate = { list.reqdate }
                    option ={ list.option }
                    text = { list.text }
                    time = { list.time }
                    payment = { list.payment } 
                    RVstatus = { list.RVstatus }
                    reloadData = { reloadData }
                    setReloadData = { setReloadData }
                    />
                })
                }
            </div>
        </div>
    )
}
export default Main;