import './Login.css';

import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import AuthService from "../../services/auth.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserService from '../../services/user.service';
import { setReduxLogin } from '../../redux/userSlice'
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useState } from "react";

const Login = ()=>{
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const [ inputs, setInputs ] = useState({
        ID : '',
        Password : ''
    })

    const {
        ID,
        Password
    } = inputs

    const HandleInputs = (e)=>{
        setInputs({
            ...inputs,
            [e.target.name] : e.target.value
        })
    }

    const [ viewPW, setViewPW ] = useState(false);

    const LoginBtn = async ()=>{
        const loginSuccess = (item)=>{
            dispatch(setReduxLogin({ID : item.resultUser.ID, Name : item.resultUser.Name}));
            navigate('/')
        }
        await UserService.login( ID, Password ).then(result => {
            console.log(result.data.message)
            if(result.data.message == "ID ERR") {
                return alert("해당 아이디가 없습니다.")
            }
            if(result.data.message == "PW ERR") {
                return alert("비밀번호가 틀렸습니다.")
            }
            if(result.data.message == "success") {
                return loginSuccess(result.data);
            }
        })
    }
    // const LoginBtn = ()=>{
    //     if (ID == Admin.ID && Password == Admin.Password){
    //         dispatch(setReduxLogin({ID : Admin.ID, Name : Admin.Name}));
    //         navigate('/')
    //     } else {
    //         if( ID != Admin.ID ){
    //             return alert('해당 아이디가 없습니다.')
    //         }
    //         if ( Password != Admin.Password){
    //             return alert('비밀번호가 틀렸습니다.')
    //         }
    //     }
    // }
    return(
        <div className="Login AppContainer">
            <div className="LoginContainer DF-Col Container">
                <h2 className="LoginTitle">관리자 페이지</h2>
                <div className="IDWrap DF-Col">
                    <label className='IDLabel' htmlFor="ID">아이디</label>
                    <input className='IDInput' id="ID" name="ID" type="text" onChange={ HandleInputs } />
                </div>
                <div className="PWWrap DF-Col">
                    <label className='PWLabel' htmlFor="PW">비밀번호</label>
                    <input className='PWInput' id="Password" name="Password" type={!viewPW ? "password" : "text"} onChange={ HandleInputs }/>
                    {
                        !viewPW
                        ? <FontAwesomeIcon className="CP Eyes" onClick={()=>{
                            setViewPW(true)
                        }} icon={ faEye }/>
                        : <FontAwesomeIcon className="CP Eyes" onClick={()=>{
                            setViewPW(false)
                        }} icon={ faEyeSlash }/>
                    }
                </div>
                <button className="LoginBtn" onClick={ LoginBtn }>로그인</button>
            </div>
        </div>
    )
}
export default Login;